(function() {
  'use strict';

  angular
    .module('frontend', ['ngAnimate', 'ngCookies', 'ngTouch', 'ngSanitize', 'ngMessages', 'ngAria', 'ngResource', 'ui.bootstrap', 'toastr']);

})();

(function() {
  'use strict';

  angular
      .module('frontend')
      .service('webDevTec', webDevTec);

  /** @ngInject */
  function webDevTec() {
    var data = [
      {
        'title': 'AngularJS',
        'url': 'https://angularjs.org/',
        'description': 'HTML enhanced for web apps!',
        'logo': 'angular.png'
      },
      {
        'title': 'BrowserSync',
        'url': 'http://browsersync.io/',
        'description': 'Time-saving synchronised browser testing.',
        'logo': 'browsersync.png'
      },
      {
        'title': 'GulpJS',
        'url': 'http://gulpjs.com/',
        'description': 'The streaming build system.',
        'logo': 'gulp.png'
      },
      {
        'title': 'Jasmine',
        'url': 'http://jasmine.github.io/',
        'description': 'Behavior-Driven JavaScript.',
        'logo': 'jasmine.png'
      },
      {
        'title': 'Karma',
        'url': 'http://karma-runner.github.io/',
        'description': 'Spectacular Test Runner for JavaScript.',
        'logo': 'karma.png'
      },
      {
        'title': 'Protractor',
        'url': 'https://github.com/angular/protractor',
        'description': 'End to end test framework for AngularJS applications built on top of WebDriverJS.',
        'logo': 'protractor.png'
      },
      {
        'title': 'Bootstrap',
        'url': 'http://getbootstrap.com/',
        'description': 'Bootstrap is the most popular HTML, CSS, and JS framework for developing responsive, mobile first projects on the web.',
        'logo': 'bootstrap.png'
      },
      {
        'title': 'Angular UI Bootstrap',
        'url': 'http://angular-ui.github.io/bootstrap/',
        'description': 'Bootstrap components written in pure AngularJS by the AngularUI Team.',
        'logo': 'ui-bootstrap.png'
      },
      {
        'title': 'Sass (Node)',
        'url': 'https://github.com/sass/node-sass',
        'description': 'Node.js binding to libsass, the C version of the popular stylesheet preprocessor, Sass.',
        'logo': 'node-sass.png'
      }
    ];

    this.getTec = getTec;

    function getTec() {
      return data;
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('navBar', navBar);

  /** @ngInject */
  function navBar($window) {
    var directive = {
      restrict: 'E',
      scope: {

      },
      link: linkFunc,
      controller: NavbarController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {

      var $body = angular.element('body');
      var $navbarContainer = el.find('.nav-bar-container');
      var $navbarContainerWrap = el.find('.nav-bar-container-wrap');

      var $btnMobileNavigation = el.find('.btn-mobile-navigation-toggle');
      var $mobileNavigation = el.find('.mobile-nav-container-wrap');

      var $navLogo = el.find('.nav-logo');

      var buildNavigationItems = function()
      {
        var $ul = el.find('.nav-items-list');
        var $mobileUl = el.find('.mobile-nav-items-list');
        $ul.empty();
        $mobileUl.empty();

        var navStr = '';
        angular.forEach(angular.element('.section-container'), function(obj, i)
        {
          var $section = angular.element(obj);
          var id = $section.attr('id');
          var title = $section.attr('data-sectiontitle');
          var linkType = $section.attr('data-linktype');

          switch(linkType) {
            case 'btn' :
              navStr += '<li data-sectionfor="'+id+'"><a href="#'+id+'" title="'+title+'" class="blam-btn btn-bg-blue hidden-xs">'+title+'</a><a href="#'+id+'" title="'+title+'" class="visible-xs">'+title+'</a></li>';
              break;

            default :
              navStr += '<li data-sectionfor="'+id+'"><a href="#'+id+'" title="'+title+'">'+title+'</a></li>';
              break;
          }


        });

        $ul.html(navStr);
        $mobileUl.html(navStr);

        // $mobileUl.find('a').removeClass('blam-btn');
        // $mobileUl.find('a').removeClass('btn-bg-blue');

        $mobileUl.find('a').on('click', vm.onNavigation_clickHandler);
        $ul.find('a').on('click', vm.onNavigation_clickHandler);
      }

      vm.onNavigation_clickHandler = function(ev)
      {
        ev.preventDefault();

        var $target = angular.element(ev.currentTarget);
        var section = $target.attr('href');
        var elementTop = angular.element(section).offset().top;

        $body.removeClass('mobile-navigation-active');
        $navbarContainerWrap.removeClass('mobile-open');
        TweenMax.to($mobileNavigation, 0.4, {x:'100%', ease:Quint.easeInOut});
        TweenMax.to(angular.element($window), 0.4, {scrollTo:{y:elementTop-58, delay: 0.4}});
      }

      vm.onMobileNavigationToggle_clickHandler = function(ev)
      {
        ev.preventDefault();

        var $dynamicModulesStart = angular.element('.site-dynamic-modules');
        var windowTop = angular.element($window).scrollTop();
        var elementTop = $dynamicModulesStart.offset().top;

        if($navbarContainerWrap.hasClass('mobile-open')) {
          $body.removeClass('mobile-navigation-active');
          $navbarContainerWrap.removeClass('mobile-open');
          TweenMax.to($mobileNavigation, 0.4, {x:'100%', ease:Quint.easeInOut});
        } else {
          $navbarContainerWrap.addClass('mobile-open');
          $body.addClass('mobile-navigation-active');

          if(windowTop < elementTop) {
            TweenMax.to(angular.element($window), 0.4, {scrollTo:{y:elementTop-58}, onComplete:function()
            {
              TweenMax.to($mobileNavigation, 1, {x:'0%', ease:Quint.easeInOut});
            }});
          } else {
            TweenMax.to($mobileNavigation, 1, {x:'0%', ease:Quint.easeInOut});
          }
        }
      }

      var onWindow_scrollHandler = function(ev)
      {
        var windowTop = angular.element($window).scrollTop();
        var elementTop = el.offset().top;

        // console.log('windowTop = ' + windowTop);
        // console.log('elementTop = ' + elementTop);

        if(windowTop >= elementTop) {
          $navbarContainerWrap.addClass('nav-bar-fixed');
        } else {
          $navbarContainerWrap.removeClass('nav-bar-fixed');
        }

        var $ul = el.find('.nav-items-list');
        var $mobileUl = el.find('.mobile-nav-items-list');
        var windowHeight = angular.element($window).height();
        var viewTop = windowTop + 80;
        var viewBottom = windowTop + windowHeight;

        var $theActive = $ul.find('li').first();

        angular.forEach($ul.find('li'), function(obj, i)
        {
          var $li = angular.element(obj);
          $li.removeClass('active');

          $mobileUl.find('li').eq(i).removeClass('active');

          var $section = angular.element('#'+$li.attr('data-sectionfor'));
          var elementTop = $section.offset().top;
          var bottom = elementTop+elementTop+$section.outerHeight();

          if((viewTop > elementTop)) { //  && (viewTop < bottom)

            $theActive = $li;
          }

        });

        if($theActive) {
          $theActive.addClass('active');
          $mobileUl.find('li').eq($theActive.index()).addClass('active');
        }

      }

      vm.onJumpTop_clickHandler = function(ev)
      {
        ev.preventDefault();

        TweenMax.to(angular.element($window), 0.4, {scrollTo:{y:0}});
      }

      function activate()
      {

        buildNavigationItems();

        angular.element($window).on('scroll', onWindow_scrollHandler);

        $btnMobileNavigation.on('click', vm.onMobileNavigationToggle_clickHandler);
        $navLogo.on('click', vm.onJumpTop_clickHandler);
        $navLogo.css('cursor', 'pointer');


        TweenMax.set($mobileNavigation, {x:'100%'});
      }

      activate();

    }

    /** @ngInject */
    function NavbarController(moment) {
      var vm = this;
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('modalOverlay', modalOverlay);

  /** @ngInject */
  function modalOverlay(Events,
                        $rootScope,
                        $timeout,
                        $window) {
    var directive = {
      restrict: 'E',
      scope: {

      },
      link: linkFunc,
      controller: ModalOverlayController,
      controllerAs: 'mc',
      bindToController: true
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {

      var STATE_MODAL_WORK = 'STATE_WORK';
      var STATE_MODAL_STORY = 'STATE_STORY';

      var onHideOverlay = function()
      {
        angular.element('.blam-modal-overlay').removeClass('blam-modal-show');
        angular.element('body').removeClass('overlay-active');
        angular.element('.story-inner-scrollable').empty();
        angular.element('.video-module').remove();
      }

      var $closeBtn = el.find('.btn-close');
      var $backBtn = el.find('.progress-back');
      var $nextBtn = el.find('.progress-next');
      var $storyBtn = el.find('.btn-story');
      var $storyBackBtn = el.find('.btn-story-back');
      var $slideWrap = el.find('.blam-modal-slider-wrap');
      var $modelContent = el.find('.blam-modal-content');

      var _storyObj;

      var onWindow_resizeHandler = function(ev)
      {
        if(angular.element($window).width() > angular.element($window).height()) {
          angular.element('.blam-modal-slide-inner').removeClass('scale-width');
          angular.element('.blam-modal-slide-inner').addClass('scale-height');
        } else {
          angular.element('.blam-modal-slide-inner').addClass('scale-width');
          angular.element('.blam-modal-slide-inner').removeClass('scale-height');
        }
      }

      var doSlide = function(to) {
        // angular.forEach(el.find('.blam-modal-slide'))
        angular.forEach($slideWrap.find('.blam-modal-slide'), function(obj, i)
        {
          if(i != to) {
            angular.element(obj).removeClass('slide-active');
            TweenMax.to(angular.element(obj), 0.5, {visibility:'hidden', opacity: 0});
          } else {
            angular.element(obj).addClass('slide-active');
            TweenMax.to(angular.element(obj), 0.5, {visibility:'visible', opacity: 1});
          }
        });
      }

      vm.onBack_clickHandler = function(ev)
      {
        ev.preventDefault();

        vm.pauseAnyVideos();

        var length = $slideWrap.find('.blam-modal-slide').length;
        var $activeSlide = $slideWrap.find('.slide-active');
        var index = $activeSlide.index();
        index--;
        if(index < 0) {
          index = 0;
        }

        doSlide(index);

        $nextBtn.removeClass('inactive');
        $backBtn.removeClass('inactive');
        if(index <= 0) {
          $backBtn.addClass('inactive');
        }
      }

      vm.pauseAnyVideos = function() {
        // Pause any videos
        angular.element('.video-module').each(function(i, obj)
        {
          var $div = angular.element(obj);
          var $iframe = $div.find('iframe').first();
          if($iframe.length > 0) {
            if($iframe.attr('src').indexOf('youtube') >= 0) {
              $iframe[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
            }

            if($iframe.attr('src').indexOf('vimeo') >= 0) {
              var player = new Vimeo.Player($iframe[0]);
              player.pause();
            }

          }
        });
      }

      vm.onNext_clickHandler = function(ev)
      {
        ev.preventDefault();

        vm.pauseAnyVideos();


        var length = $slideWrap.find('.blam-modal-slide').length;
        var $activeSlide = $slideWrap.find('.slide-active');
        var index = $activeSlide.index();
        index++;
        if(index>=length) {
          index=index-1;
        }

        doSlide(index);

        $nextBtn.removeClass('inactive');
        $backBtn.removeClass('inactive');
        if(index>= (length-1)) {
          $nextBtn.addClass('inactive');
        }
      }

      function parseVideoEmbed(embed)
      {
        var output;
        var array;
        var embedStr;

        if(embed.indexOf('youtube')>=0) {
          array = embed.split(' ');
          angular.forEach(array, function(obj, i)
          {
            if(obj.indexOf('src')>=0) {
              embedStr = obj.substring(5, obj.length-1);
              if(embedStr.indexOf('?enablejsapi=1') < 0) {
                embedStr += '?enablejsapi=1';
              }
              array[i] = 'src="'+embedStr+'"';
            }

          });
        }

        if(embed.indexOf('vimeo')>=0) {
          array = embed.split(' ');
          angular.forEach(array, function(obj, i)
          {
            if(obj.indexOf('src')>=0) {
              // embedStr = obj.substring(5, obj.length-1);
              // if(embedStr.indexOf('?api=true') < 0) {
              //   embedStr += '?api=true';
              // }
              // array[i] = 'src="'+embedStr+'"';

              array[i] = array[i];
            }

          });
        }

        output = array.join(' ');
        return output;
      }

      vm.onStory_clickHandler = function(ev)
      {
        ev.preventDefault();

        switch($modelContent.attr('data-state'))
        {
          case STATE_MODAL_WORK :
            TweenMax.to($slideWrap, 0.8, {x: '-100%', ease:Quint.easeInOut});
            $modelContent.attr('data-state', STATE_MODAL_STORY);
            break;

          case STATE_MODAL_STORY :
            TweenMax.to($slideWrap, 0.8, {x: '0%', ease:Quint.easeInOut});
            $modelContent.attr('data-state', STATE_MODAL_WORK);
            break;
        }
      }

      vm.onClose_clickHandler = function(ev)
      {
        ev.preventDefault();

        onHideOverlay();
      }

      vm.onOpenWorkModal_eventHandler = function(ev, params)
      {
        reset();

        if(params && params.storyObj) {
          _storyObj = params.storyObj;
          init();
        }


      }

      var reset = function()
      {
        $closeBtn.off('click', vm.onClose_clickHandler);
        $nextBtn.off('click', vm.onNext_clickHandler);
        $backBtn.off('click', vm.onBack_clickHandler);
        $storyBtn.off('click', vm.onStory_clickHandler);
        $storyBackBtn.off('click', vm.onStory_clickHandler);

        TweenMax.set($slideWrap, {clearProps:"all"});
        angular.forEach($slideWrap.find('.blam-modal-slide'), function(obj, i)
        {
          angular.element(obj).removeClass('slide-active');
          TweenMax.set($(obj), {clearProps:"all"});
        });
        $slideWrap.find('.blam-modal-slide').first().addClass('slide-active');

        $closeBtn = el.find('.btn-close');
        $backBtn = el.find('.progress-back');
        $nextBtn = el.find('.progress-next');
        $storyBtn = el.find('.btn-story');
        $storyBackBtn = el.find('.btn-story-back');

        $nextBtn.removeClass('inactive');
        $backBtn.addClass('inactive');
      }

      var init = function()
      {
        angular.element($window).off('resize', onWindow_resizeHandler);
        angular.element($window).on('resize', onWindow_resizeHandler);

        $closeBtn.on('click', vm.onClose_clickHandler);
        $nextBtn.on('click', vm.onNext_clickHandler);
        $backBtn.on('click', vm.onBack_clickHandler);
        $storyBtn.on('click', vm.onStory_clickHandler);
        $storyBackBtn.on('click', vm.onStory_clickHandler);

        $slideWrap.find('.blam-modal-slide').remove();
        if(_storyObj)
        {
          var slidesStr = '';
          angular.forEach(_storyObj.Slides, function(obj, i)
          {
            if(obj.Type == 'image'){
              slidesStr += '<div class="blam-modal-slide slide-image">\n' +
                '            <p class="lead title text-center">'+obj.Title+'</p>\n' +
                '            <div class="blam-modal-slide-inner">\n' +
                '              <img src="'+obj.ImageSrc+'">\n' +
                '            </div>\n' +
                '          </div>';
            }

            if(obj.Type == 'video'){


              var formatted = parseVideoEmbed(obj.VideoEmbed);

              slidesStr += '<div class="blam-modal-slide slide-video">\n' +
                '            <p class="lead title text-center">'+obj.Title+'</p>\n' +
                '            <div class="blam-modal-slide-inner"><div class="video-module"><img src="/themes/blam/assets/images/video-scaler.png" class="video-scaler" />'+formatted+'</div></div>\n' +
                '          </div>';
            }

            if(obj.Type == 'text'){
              slidesStr += '<div class="blam-modal-slide"><p class="lead title text-center">'+obj.Title+'</p><div class="blam-modal-text-wrap"><div class="blam-modal-text-inner">\n'+obj.HtmlSrc+'</div></div></div>';
            }
          });

          $slideWrap.prepend(slidesStr);
          $slideWrap.find('.blam-modal-slide').first().addClass('slide-active');

          $modelContent.find('.story-inner-scrollable').html(_storyObj.Story.Html+'<p class=\"text-center\"><button class=\"blam-btn btn-bg-blue btn-story-back\">Back</button></p>');
          $modelContent.find('.title-inner').text(_storyObj.Story.Title);

          angular.element('.story-inner-scrollable').find('.btn-story-back').on('click', vm.onStory_clickHandler);

          $timeout(function()
          {
            angular.element('.blam-modal-overlay').addClass('blam-modal-show');


            $timeout(function()
            {

              angular.element('.blam-modal-slide').each(function(i, obj) {
                var $ele = angular.element(obj);
                var element = $ele[0];
                if($ele.hasClass('slide-image')) {
                  element = $ele[0];

                  vm.addHammerEvents(element);
                } else {
                  if($ele.find('.blam-modal-text-wrap').length > 0) {
                    element = $ele.find('.blam-modal-text-wrap')[0];

                    // vm.addHammerEvents($ele[0]);
                    vm.addHammerEvents(element);
                  }
                }

                // console.log(i);


              });

            }, 1000);


          }, 100);

          onWindow_resizeHandler();

        }
      }

      vm.addHammerEvents = function(element) {
        var hammertime = new Hammer(element);
        hammertime.on('swipe', function(ev) {

         if(angular.element($window).width() >= 768) return;

          if(ev.deltaX > 0) {
            vm.onBack_clickHandler(ev);
          } else {
            vm.onNext_clickHandler(ev);
          }
        });
      }

      init();

      $rootScope.$on(Events.OPEN_WORK_MODAL, vm.onOpenWorkModal_eventHandler);
    }

    /** @ngInject */
    function ModalOverlayController(moment, $scope) {
      var vm = this;
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('acmeMalarkey', acmeMalarkey);

  /** @ngInject */
  function acmeMalarkey(malarkey) {
    var directive = {
      restrict: 'E',
      scope: {
        extraValues: '='
      },
      template: '&nbsp;',
      link: linkFunc,
      controller: MalarkeyController,
      controllerAs: 'vm'
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {
      var watcher;
      var typist = malarkey(el[0], {
        typeSpeed: 40,
        deleteSpeed: 40,
        pauseDelay: 800,
        loop: true,
        postfix: ' '
      });

      el.addClass('acme-malarkey');

      angular.forEach(scope.extraValues, function(value) {
        typist.type(value).pause().delete();
      });

      watcher = scope.$watch('vm.contributors', function() {
        angular.forEach(vm.contributors, function(contributor) {
          typist.type(contributor.login).pause().delete();
        });
      });

      scope.$on('$destroy', function () {
        watcher();
      });
    }

    /** @ngInject */
    function MalarkeyController($log, githubContributor) {
      var vm = this;

      vm.contributors = [];

      activate();

      function activate() {
        return getContributors().then(function() {
          $log.info('Activated Contributors View');
        });
      }

      function getContributors() {
        return githubContributor.getContributors(10).then(function(data) {
          vm.contributors = data;

          return vm.contributors;
        });
      }
    }

  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('headerMain', headerMain);

  /** @ngInject */
  function headerMain($window) {
    var directive = {
      restrict: 'E',
      scope: {

      },
      link: linkFunc,
      controller: HeadermainController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {


      function setHeight()
      {
        TweenMax.set(el, {height: angular.element($window).height()});
      }


      vm.onWindow_resizeHandler = function(ev)
      {
        setHeight();
      }

      angular.element($window).on('resize', vm.onWindow_resizeHandler);
      setHeight();
    }

    /** @ngInject */
    function HeadermainController(moment) {
      var vm = this;
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .factory('githubContributor', githubContributor);

  /** @ngInject */
  function githubContributor($log, $http) {
    var apiHost = 'https://api.github.com/repos/Swiip/generator-gulp-angular';

    var service = {
      apiHost: apiHost,
      getContributors: getContributors
    };

    return service;

    function getContributors(limit) {
      if (!limit) {
        limit = 30;
      }

      return $http.get(apiHost + '/contributors?per_page=' + limit)
        .then(getContributorsComplete)
        .catch(getContributorsFailed);

      function getContributorsComplete(response) {
        return response.data;
      }

      function getContributorsFailed(error) {
        $log.error('XHR Failed for getContributors.\n' + angular.toJson(error.data, true));
      }
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('bladeStaticThreecolumn', bladeStaticThreecolumn);

  /** @ngInject */
  function bladeStaticThreecolumn() {
    var directive = {
      restrict: 'E',
      scope: {

      },
      link: linkFunc,
      controller: BladeStaticThreeColumnController,
      controllerAs: 'vm'
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {

      scope.$on('$destroy', function () {
        // watcher();
      });
    }

    /** @ngInject */
    function BladeStaticThreeColumnController($log, githubContributor) {

    }

  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('bladeStaticOnecolumn', bladeStaticOnecolumn);

  /** @ngInject */
  function bladeStaticOnecolumn() {
    var directive = {
      restrict: 'E',
      scope: {

      },
      link: linkFunc,
      controller: BladeStaticOneColumnController,
      controllerAs: 'vm'
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {

      scope.$on('$destroy', function () {
        // watcher();
      });
    }

    /** @ngInject */
    function BladeStaticOneColumnController($log, githubContributor) {

    }

  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('bladeContentSlide', bladeContentSlide);

  /** @ngInject */
  function bladeContentSlide($log, $window) {
    var directive = {
      restrict: 'E',
      scope: {

      },
      link: linkFunc,
      controller: BladeContentSlideController,
      controllerAs: 'vm'
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {

      var hammertime = new Hammer(el[0]);
      hammertime.on('swipe', function(ev) {

        if(angular.element($window).width() >= 768) return;

        if(ev.deltaX > 0) {
          vm.onBack_clickHandler(ev);
        } else {
          vm.onNext_clickHandler(ev);
        }
      });

      var $mask = el.find('.content-slider-wrap').first();
      var slides = $mask.find('.content-slide');
      var $progressList = el.find('.content-slide-progress ul').first();
      var $btnNext = el.find('.progress-action.progress-next');
      var $btnBack = el.find('.progress-action.progress-back');

      var init = function()
      {
        el.attr('data-slideindex', 0);

        angular.forEach(slides, function(obj, i)
        {

          var $slide = angular.element(obj);
          if(i != 0) {
            TweenMax.set($slide, {x:'100%'});
            $progressList.append('<li></li>');
          } else {
            $progressList.append('<li class="active"></li>');
          }
        });

        $btnNext.on('click', vm.onNext_clickHandler);
        $btnBack.on('click', vm.onBack_clickHandler);

        if(slides.length <= 1)
        {
          $btnNext.addClass('hidden');
          $btnBack.addClass('hidden');
        }
      }

      vm.doSlide = function(toIndex)
      {
        if(el.attr('data-isanimating') == 'true'){
          return;
        }

        if(toIndex>=slides.length) {
          return;
        }
        if(toIndex<0) {
          return;
        }

        el.attr('data-isanimating', true);

        var currentIndex = parseInt(el.attr('data-slideindex'));
        var $current = angular.element(slides[currentIndex]);
        var $to = angular.element(slides[toIndex]);

        if(toIndex>currentIndex) {
          TweenMax.to($current, 0.8, {x:'-100%', ease:Expo.easeInOut});
          TweenMax.to($to, 0.8, {x:'0%', ease:Expo.easeInOut, onComplete:function()
          {
            el.attr('data-isanimating', false);
          }});
        } else {
          TweenMax.to($current, 0.8, {x:'100%', ease:Expo.easeInOut});
          TweenMax.to($to, 0.8, {x:'0%', ease:Expo.easeInOut, onComplete:function()
          {
            el.attr('data-isanimating', false);
          }});
        }

        el.attr('data-slideindex', toIndex);
        vm.updateProgress(toIndex);
        vm.updateBtnStatus(toIndex);

      }

      vm.updateBtnStatus = function(toIndex)
      {
        $btnBack.removeClass('inactive');
        $btnNext.removeClass('inactive');

        if(toIndex == 0) {
          $btnBack.addClass('inactive');
        }

        if(toIndex == (slides.length-1)) {
          $btnNext.addClass('inactive');
        }
      }

      vm.updateProgress = function(toIndex)
      {
        angular.forEach($progressList.find('li'), function(obj, i)
        {
          var $item = angular.element(obj);
          $item.removeClass('active');
          if(i == toIndex) {
            $item.addClass('active');
          }
        });
      }

      vm.onNext_clickHandler = function(ev)
      {
        ev.preventDefault();

        // console.log('Next');

        var currentIndex = parseInt(el.attr('data-slideindex'));
        // console.log(currentIndex);
        var to = currentIndex+=1;
        vm.doSlide(to);
      }

      vm.onBack_clickHandler = function(ev)
      {
        ev.preventDefault();

        // console.log('Back');
        var currentIndex = parseInt(el.attr('data-slideindex'));
        var to = currentIndex-=1;
        vm.doSlide(to);
      }

      scope.$on('$destroy', function () {
        // watcher();
      });

      init();
    }

    /** @ngInject */
    function BladeContentSlideController($log, githubContributor) {
      var vm = this;

      //
      // vm.contributors = [];
      //
      // activate();
      //
      // function activate() {
      //   return getContributors().then(function() {
      //     $log.info('Activated Contributors View');
      //   });
      // }

      // function getContributors() {
      //   return githubContributor.getContributors(10).then(function(data) {
      //     vm.contributors = data;
      //
      //     return vm.contributors;
      //   });
      // }
    }

  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .directive('bladeContentModal', bladeContentModal);

  /** @ngInject */
  function bladeContentModal(Events,
                             $rootScope,
                             $window) {
    var directive = {
      restrict: 'E',
      scope: {

      },
      link: linkFunc,
      controller: BladeContentModalController,
      controllerAs: 'vm'
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {

      var previews = el.find('.modal-preview-item').parent();
      // console.log(previews);

      var _humourousText = [{
        title: 'This bit is empty',
        description : 'Work is up on blocks with the wheels off. Road testing soon.'
      }, {
        title: 'Nothing here yet either',
        description : 'Work still on the bench, in bits. Needs more spannering.'
      }];

      var _$window = angular.element($window);

      vm.onPreview_clickHandler = function(ev)
      {
        ev.preventDefault();

        angular.element('body').addClass('overlay-active');

        var $currentTarget = angular.element(ev.currentTarget);
        var workJsonStr = $currentTarget.data('slidestory');
        var workJsonObj = JSON.parse(workJsonStr);

        $rootScope.$broadcast(Events.OPEN_WORK_MODAL, {storyObj:workJsonObj});
      }

      var fillEmptyColumns = function()
      {
        var temp = angular.copy(previews);
        // console.log(temp);

        // return;

        var $ele = el;
        var $row = $ele.find('.row').first();
        $row.empty();

        var i = 0;

        var divide;
        var roundedUp;
        var multiplyBy;
        var createNew;

        if((_$window.width() >= 1200) || (_$window.width() >= 992)) {
          divide = previews.length/3;
          roundedUp = Math.ceil(divide);
          multiplyBy = roundedUp * 3;
          createNew = multiplyBy-previews.length;

          // console.log(multiplyBy);
          // console.log(createNew);

          for(i = 0; i < createNew; i++)
          {
            temp.push(angular.element('<div class="col-lg-4 col-md-4 col-sm-6 preview-filler">\n' +
              '              <div class="modal-preview-item">\n' +
              '                <div class="preview-image-wrap">\n' +
              '                  <div class="preview-image preview-filler-image" style="background-image: url(\'https://blam.nz/assets/Uploads/work-thumbs-coming-soon-2x.jpg\');"></div>\n' +
              '                </div>\n' +
              '                <div class="modal-preview-title">'+_humourousText[i].title+'</div>\n' +
              '                <div class="modal-preview-blurb">'+_humourousText[i].description+'</div>\n' +
              '              </div>\n' +
              '            </div>'));
          }
        } else if((_$window.width() >= 768)) {
          divide = previews.length/2;
          roundedUp = Math.ceil(divide);
          multiplyBy = roundedUp * 2;
          createNew = multiplyBy-previews.length;

          // console.log(multiplyBy);
          // console.log(createNew);

          for(i = 0; i < createNew; i++)
          {
            temp.push(angular.element('<div class="col-lg-4 col-md-4 col-sm-6 preview-filler">\n' +
              '              <div class="modal-preview-item">\n' +
              '                <div class="preview-image-wrap">\n' +
              '                  <div class="preview-image preview-filler-image" style="background-image: url(\'https://blam.nz/assets/Uploads/work-thumbs-coming-soon-2x.jpg\');"></div>\n' +
              '                </div>\n' +
              '                <div class="modal-preview-title">'+_humourousText[i].title+'</div>\n' +
              '                <div class="modal-preview-blurb">'+_humourousText[i].description+'</div>\n' +
              '              </div>\n' +
              '            </div>'));
          }
        }
        // console.log(temp);
        // console.log($row);

        angular.forEach(temp, function(obj, i)
        {
          // console.log('num = ' + i);
          // console.log(obj);
          $row.append(obj);
          var $preview = angular.element(obj).find('.preview-image');
          if(!$preview.hasClass('preview-filler-image')) {
                $preview.on('click', vm.onPreview_clickHandler);
              }
        });

        // previews = el.find('.preview-image');
        //
        // angular.forEach(previews, function(obj, i)
        // {
        //   var $preview = angular.element(obj);
        //   if(!$preview.hasClass('preview-filler-image')) {
        //     $preview.on('click', vm.onPreview_clickHandler);
        //   }
        //
        // });
      }

      vm.onWindow_resizeHandler = function(ev)
      {
        fillEmptyColumns();
      }

      var init = function()
      {
        // el.find();

        _$window.on('resize', vm.onWindow_resizeHandler);
        fillEmptyColumns();


      }

      init();

      scope.$on('$destroy', function () {
        // watcher();
      });
    }

    /** @ngInject */
    function BladeContentModalController($log, githubContributor) {
      // var vm = this;
      //
      // vm.contributors = [];
      //
      // activate();
      //
      // function activate() {
      //   return getContributors().then(function() {
      //     $log.info('Activated Contributors View');
      //   });
      // }

      // function getContributors() {
      //   return githubContributor.getContributors(10).then(function(data) {
      //     vm.contributors = data;
      //
      //     return vm.contributors;
      //   });
      // }
    }

  }

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .controller('MainController', MainController);

  /** @ngInject */
  function MainController($timeout,
                          webDevTec,
                          toastr,
                          $window) {
    var vm = this;

    vm.awesomeThings = [];
    vm.classAnimation = '';
    vm.creationDate = 1503625600904;
    vm.showToastr = showToastr;



    // vm.onStoryBack_clickHandler = function(ev)
    // {
    //   ev.preventDefault();
    //
    //   console.log('onStoryBack_clickHandler');
    // }

    vm.onPromptNext_clickHandler = function(ev)
    {
      ev.preventDefault();

      var $element = angular.element(ev.currentTarget);
      var to = $element.attr('data-nextto');

      TweenMax.to(angular.element($window), 1, {scrollTo:{y:angular.element(to).offset().top-58}, ease:Expo.easeInOut})
    }

    activate();

    function activate() {
      getWebDevTec();
      $timeout(function() {
        angular.element('body').addClass('fadeIn');
      }, 300);
    }

    function showToastr() {
      toastr.info('Fork <a href="https://github.com/Swiip/generator-gulp-angular" target="_blank"><b>generator-gulp-angular</b></a>');
      vm.classAnimation = '';
    }

    function getWebDevTec() {
      vm.awesomeThings = webDevTec.getTec();

      angular.forEach(vm.awesomeThings, function(awesomeThing) {
        awesomeThing.rank = Math.random();
      });
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .run(runBlock);

  /** @ngInject */
  function runBlock($log) {

    $log.debug('runBlock end');
  }

})();

/* global malarkey:false, moment:false */
(function() {
  'use strict';

  angular
    .module('frontend')
    .constant('malarkey', malarkey)

    .constant('Events', {
      OPEN_WORK_MODAL : 'open_work_modal',
      CLOSE_WORK_MODAL : 'close_work_modal',
    })

    .constant('moment', moment);

})();

(function() {
  'use strict';

  angular
    .module('frontend')
    .config(config);

  /** @ngInject */
  function config($logProvider, toastrConfig) {
    // Enable log
    $logProvider.debugEnabled(true);

    // Set options third-party lib
    toastrConfig.allowHtml = true;
    toastrConfig.timeOut = 3000;
    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventDuplicates = true;
    toastrConfig.progressBar = true;
  }

})();

angular.module("frontend").run(["$templateCache", function($templateCache) {$templateCache.put("app/components/navbar/navbar.html","<nav class=\"navbar navbar-static-top navbar-inverse\"><div class=container-fluid><div class=navbar-header><a class=navbar-brand href=https://github.com/Swiip/generator-gulp-angular><span class=\"glyphicon glyphicon-home\"></span> Gulp Angular</a></div><div class=\"collapse navbar-collapse\" id=bs-example-navbar-collapse-6><ul class=\"nav navbar-nav\"><li class=active><a ng-href=#>Home</a></li><li><a ng-href=#>About</a></li><li><a ng-href=#>Contact</a></li></ul><ul class=\"nav navbar-nav navbar-right acme-navbar-text\"><li>Application was created {{ vm.relativeDate }}.</li></ul></div></div></nav>");}]);